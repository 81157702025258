/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Col,
  FormGroup,
  Form,
  Input,
  Row
} from "reactstrap";
// core components


const S3Form = (props)=>{

  return (
    <Form role="form">
      <Row>
        <Col lg="6">
          <FormGroup>
            <label className="form-control-label px-2" >S3 Access Key</label>
            <Input
              className="input-group-alternative mb-3"
              name="s3_access_key"
              type="text"
              value={props.cluster.s3_access_key}
              onChange={props.handleInputChange}
            />
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup>
            <label className="form-control-label px-2" >S3 Secret Key</label>
            <Input
              className="input-group-alternative mb-3"
              name="s3_secret_key"
              type="password"
              value={props.cluster.s3_secret_key}
              onChange={props.handleInputChange}
            />
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
}


export default S3Form;
