/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import PasswordStrengthBar from 'react-password-strength-bar';

// reactstrap components
import {
  Alert,
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Modal,
  Container,
  Row,
  Col
} from "reactstrap";
// core components
import { userService, registerService, authenticationService, openFastspringManage } from 'services/';

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: '',
      enableEdit: false,
      hasEdit: false,
      submitModal: false,
      passwordMatched: true,
      isChangePassword: false,
      isDelete: false,
      isLoading: false,
      buttonDisabled: false,
      matchError: null,
      error: null
    };
    this.handleEdit = this.handleEdit.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount(){
    userService.getAll().then(
      data => this.setState({user: data.user}),
      error => null
      )
  }

  handleEdit(event){
    event.preventDefault()
    if (this.state.enableEdit) {
      // Don't bring up modal if there is no edit
      if (this.state.hasEdit){
        this.setState({submitModal: true})
      } else {
        this.setState({enableEdit: false})
      }
    } else {
      this.setState({enableEdit: true})
    }

  }

  handleInputChange(event) {
    if (this.state.enableEdit || this.state.isDelete || this.state.isChangePassword) {
      const {value, name} = event.target
      let user = this.state.user
      user[name] = value
      this.setState({user:user, hasEdit: true})
    }
    if (this.state.user.new_password !== null && this.state.user.new_password !== this.state.user.new_password2) {
      this.setState({matchError: "passwords must match", buttonDisabled: true, passwordMatched: false})
    } else {
      this.setState({matchError: null, buttonDisabled: false, passwordMatched: true})
    }
  }

  handleSubmit(event){
    event.preventDefault()
    this.setState({ isLoading: true, buttonDisabled: true, error: null });
    if (this.state.isDelete){
      registerService._delete(this.state.user.email, this.state.user.password).then(
        data => {authenticationService.logout()
                 this.props.history.push({ pathname: "/auth/register" })},
        error => this.setState({ isLoading: false, buttonDisabled: false, error: error}))
    } else {
      registerService.update(JSON.stringify(this.state.user)).then(
        data => window.location.reload(false),
        error => this.setState({ isLoading: false, buttonDisabled: false, error: error}))
    }
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
              <Row className="align-items-center">
                <Col xs="8">
                  <h3 className="mb-0">My account</h3>
                </Col>
                <Col className="text-right" xs="4">
                  <Button
                    color={this.state.enableEdit ? "primary" : "info" }
                    onClick={this.handleEdit}
                    size="md"
                  >
                    {this.state.enableEdit ? "Save" : "Edit" }
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Form>
                <h6 className="heading-small text-muted mb-4">
                  User information
                </h6>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="input-email">Email address</label>
                        <Input className="form-control-alternative" name="email" placeholder="Email" id="input-email"
                          value={this.state.user.email} onChange={this.handleInputChange} type="email"/>
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="input-full-name">Full name</label>
                        <Input className="form-control-alternative" name="full_name" placeholder="Full name" id="input-full-name"
                          value={this.state.user.full_name || "" } onChange={this.handleInputChange}type="text"/>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              </Form>
            </CardBody>
          </Card>

          <Card className="bg-secondary shadow mt-5">
            <CardBody>
              <h6 className="heading-small text-muted mb-4">Status</h6>
              <div className="text-sm pl-lg-4 pb-4">
                {`Your account has ${this.state.user.verified?"":"not "}been verified ${this.state.user.activated?"and ":"but not "}activated.`}
                <br/>{`You are currently using ${this.state.user.clusters} cluster.`}</div>
            </CardBody>
          </Card>

          <Card className="bg-secondary shadow mt-5">
            <CardBody>
              <h6 className="heading-small text-muted mb-4">Payment Method</h6>
              <Row className="mb-4">
                <Col xs="12" lg="7" className="pl-lg-4">
                  <span className="text-sm">Your payment details are stored with our payment provider.<br/>
                    Click to update your payment method.</span>
                </Col>
                <Col xs="12" lg="5">
                  <Button className="float-right mt-3 mt-lg-0" color="success" outline type="button"
                    onClick={()=>openFastspringManage()}>
                    Update Payment Method
                  </Button>
                </Col>
              </Row>
              <hr className="mt-5" />
              <h6 className="heading-small text-muted mb-4">Security</h6>
              <Row className="mb-4">
                <Col xs="12" lg="7" className="pl-lg-4">
                  <span className="text-sm">Change the password for your account.<br/>
                    Make sure to set a strong password that is not being used elsewhere.</span>
                </Col>
                <Col xs="12" lg="5">
                  <Button className="float-right mt-3 mt-lg-0" color="warning" outline type="button"
                    onClick={()=>this.setState({submitModal: true, isChangePassword: true})}>
                    Change Account Password
                  </Button>
                </Col>
              </Row>
              <hr className="mt-5" />
              <h6 className="heading-small text-muted mb-4">Delete Account</h6>
              <Row className="mb-4">
                <Col xs="12" lg="7" className="pl-lg-4">
                  <span className="text-sm">You must delete all your clusters before deleting your account.<br/>
                    Your account will not be recoverable once it is deleted.</span>
                </Col>
                <Col xs="12" lg="5">
                  <Button className="float-right mt-3 mt-lg-0" color="danger" outline type="button"
                    onClick={()=>this.setState({submitModal: true, isDelete: true})}>
                    Permanently Delete Account
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Modal className="modal-dialog-centered" isOpen={this.state.submitModal} >
            <div className="modal-header">
              <h3 className="modal-title" id="modal-title-default">Confirm changes</h3>
            </div>
            <div className="modal-body">
              <Form onSubmit={(e)=>e.preventDefault()}>
                <div className="p-lg-4">
                  <FormGroup>
                  { this.state.error ?
                    <Alert color="danger">{this.state.error}</Alert> :
                    <label className="form-control-label" htmlFor="input-password">
                      { this.state.isChangePassword ? "Current password" : "Enter password to confirm" }
                    </label> }
                    <Input className="form-control-alternative" name="password"
                      id="input-password" placeholder="Enter password" type="password"
                      value={this.state.user.password || ""} onChange={this.handleInputChange}/>
                  </FormGroup>
                  { this.state.isChangePassword ? <>
                      <FormGroup>
                        <label className="form-control-label" htmlFor="input-password">
                          New Password
                        </label>
                        <Input className="form-control-alternative" name="new_password" id="input-password"
                          value={this.state.user.new_password || ""} onChange={this.handleInputChange} type="password"
                          placeholder="Enter new password"/>
                      </FormGroup>
                      {this.state.user.new_password?
                      <div className="text-muted font-italic">
                        <PasswordStrengthBar className="px-1 font-weight-700" password={this.state.user.new_password} />
                      </div>
                      : null }
                      <FormGroup>
                        <label className="form-control-label" htmlFor="input-password">
                          Repeat New Password
                        </label>
                        <Input className="form-control-alternative" name="new_password2" id="input-password2"
                          value={this.state.user.new_password2 || ""} onChange={this.handleInputChange} type="password"
                          placeholder="Repeat the same password"/>
                      </FormGroup>
                      {this.state.user.new_password2 && !this.state.passwordMatched?
                      <div className="text-muted text-right font-italic">
                        <small>{this.state.matchError}</small>
                      </div>
                      : null }
                   </> : null }

                </div>
                <div className="float-right pr-3">
                  <Button color="secondary" type="button"
                    onClick={()=>this.setState({submitModal: false, isDelete: false, isChangePassword: false})}>
                    Cancel
                  </Button>
                { this.state.passwordMatched ?
                  <Button color="primary" type="submit"
                    onClick={this.state.buttonDisabled ? (e)=>e.preventDefault(): this.handleSubmit}>
                    { this.state.isLoading ? "Submitting..." : "Submit" }
                  </Button>
                : null }
                </div>
              </Form>
            </div>
          </Modal>
        </Container>
      </>
    );
  }
}

export default Profile;
