/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from 'react-router-dom'

// reactstrap components
import {
  Alert,
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col
} from "reactstrap";
import { authenticationService } from '../../services/';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      isLoading: false,
      error: null
    };

    // redirect to home if already logged in
    if (authenticationService.currentUserValue) {
        this.handleRedirect();
    }

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  getParams = ()=>{
    return new URLSearchParams(this.props.location.search);
  }

  componentDidMount() {
    if (this.getParams().get('set_logged_in') === 'true') {
      authenticationService.set_logged_in()
      this.handleRedirect()
    }
  }

  handleInputChange(event) {
    const {value, name} = event.target
    this.setState({[name]: value});
  }

  handleSubmit(event) {
    event.preventDefault()
    this.setState({ isLoading: true });
    authenticationService.login(
      this.state.email, this.state.password
      ).then(() => this.setState({ isLoading: false })
      ).then(
            user => {
              this.handleRedirect()
            },
            error => {
                this.setState({
                  isLoading: false,
                  error: error});
            }
        )
  }

  handleRedirect(){
    const from = JSON.parse(localStorage.getItem('redirectUri')) || { pathname: "/" };
    localStorage.removeItem('redirectUri');
    this.props.history.push(from);
  }

  render() {
    return (
      <>
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-white pb-5">
              <div className="text-muted text-center mb-3">
                <small>Sign in with</small>
              </div>
              <div className="btn-wrapper text-center">
                <Button
                  className="btn-neutral btn-icon"
                  color="default"
                  href={process.env.REACT_APP_API_URL+'/auth/github'}
                >
                  <span className="btn-inner--icon mr-1">
                    <img
                      alt="..."
                      src={
                        require("assets/img/icons/common/github.svg")
                          .default
                      }
                    />
                  </span>
                  <span className="btn-inner--text">Github</span>
                </Button>
                <Button
                  className="btn-neutral btn-icon ml-1"
                  color="default"
                  href={process.env.REACT_APP_API_URL+'/auth/google'}
                >
                  <span className="btn-inner--icon mr-1">
                    <img
                      alt="..."
                      src={
                        require("assets/img/icons/common/google.svg")
                          .default
                      }
                    />
                  </span>
                  <span className="btn-inner--text">Google</span>
                </Button>
              </div>
            </CardHeader>

            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-5">
                { this.state.error ? 
                  <Alert color="danger">{this.state.error}</Alert> : 
                  <small>Sign in with your email and password</small> }
              </div>
              <Form role="form">
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input name="email" placeholder="Email" type="text" autoComplete="new-email" onChange={this.handleInputChange}/>
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input name="password" placeholder="Password" type="password" autoComplete="new-password" onChange={this.handleInputChange}/>
                  </InputGroup>
                </FormGroup>
                <div className="text-center">
                  <Button 
                    className="my-4" 
                    color="primary" 
                    type="sumbit"
                    onClick={ this.state.isLoading ? (e)=>e.preventDefault() : this.handleSubmit }
                  >
                    { this.state.isLoading ? "Signing in..." : "Sign in" }
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
          <Row className="mt-3">
            <Col xs="6">
              <Link className="text-light" to="/auth/forget">
                <small>Forgot password?</small>
              </Link>
            </Col>
            <Col className="text-right" xs="6">
              <Link className="text-light" to="/auth/register">
                <small>Create new account</small>
              </Link>
            </Col>
          </Row>
        </Col>
      </>
    );
  }
}

export default Login;
