/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// core components
import { Route, Switch, Redirect } from "react-router-dom";

import { clusterService } from 'services/';

import List from "views/cluster/List.js";
import Create from "views/cluster/Create.js";
import Details from "views/cluster/Details.js";
import Update from "views/cluster/Update.js";
import Delete from "views/cluster/Delete.js";

class Clusters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clusters: [],
      isEmpty: false,
      intervalId: null
    };

    this.updateClusters = this.updateClusters.bind(this);
  }

  updateClusters(){
    clusterService.read().then(
      data => this.setState({clusters: data.clusters, isEmpty: data.clusters.length === 0}),
      error => null
      )
  }

  componentDidMount(){
    this.updateClusters()
    var intervalId = setInterval(this.updateClusters, 10000)
    this.setState({intervalId: intervalId});
  }

  componentWillUnmount() {
     clearInterval(this.state.intervalId);
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Switch>
          <Route path="/admin/cluster/create" render={props => <Create {...props} updateClusters={this.updateClusters} />} />
          <Route path="/admin/cluster/update/:name" render={props => <Update {...props} updateClusters={this.updateClusters} cluster={this.state.clusters.find(cluster => cluster.name === props.match.params.name)} />} />
          <Route path="/admin/cluster/details/:name" render={props => <Details {...props} cluster={this.state.clusters.find(cluster => cluster.name === props.match.params.name)} />} />
          <Route path="/admin/cluster/delete/:name" render={props => <Delete {...props} updateClusters={this.updateClusters} />} />
          <Route path="/admin/cluster" exact render={props => <List clusters={this.state.clusters} isEmpty={this.state.isEmpty} {...props} />} />
          <Redirect from="*" to="/admin/cluster"/>
        </Switch>
      </>
    );
  }
}

export default Clusters;
