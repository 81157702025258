import { BehaviorSubject } from 'rxjs';
import { handleResponse } from '../helpers';

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));

export const authenticationService = {
    login,
    logout,
    set_logged_in,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue () { return currentUserSubject.value }
};

function login(email, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({ email, password })
    };

    return fetch(process.env.REACT_APP_API_URL+'/auth/email', requestOptions)
        .then(handleResponse)
        .then(user => {
            localStorage.setItem('currentUser', JSON.stringify(user));
            currentUserSubject.next(user);

            return user;
        });
}

function set_logged_in() {
    const user = {'logged_in': true}
    localStorage.setItem('currentUser', JSON.stringify(user));
    currentUserSubject.next(user);
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    currentUserSubject.next(null);
    document.cookie = "access_token_cookie=; domain=.clusternest.com; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "csrf_access_token=; domain=.clusternest.com; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}
