/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import {Link} from 'react-router-dom'

// reactstrap components
import {
  Alert,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Container,
  Form,
  FormGroup,
  Input,
  Row
} from "reactstrap";
// core components
import { clusterService } from 'services/';

class Delete extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMatched: false,
      isLoading: false,
      buttonDisabled: true,
      error: null
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange(event) {
    if (event.target.value === this.props.match.params.name){
      this.setState({isMatched: true, buttonDisabled: false});
    } else {
      this.setState({isMatched: false, buttonDisabled: true});
    }
  }

  handleSubmit(event) {
    if ( !this.state.isMatched ){
      this.setState({error: "Cluster name is required"});
      return null
    }
    this.setState({ isLoading: true, buttonDisabled: true, error: null });
    clusterService._delete({name: this.props.match.params.name})
    .then(
        user => this.deletionSuccess(),
        error => this.setState({ isLoading: false, buttonDisabled: false, error: error})
    )
  }

  deletionSuccess(){
    this.props.updateClusters()
    this.props.history.push({ pathname: "/admin/cluster" })
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="align-middle mb-0">Delete Cluster</h3>
                </CardHeader>
                <CardBody className="px-lg-5 py-lg-3 bg-secondary">
                  <div className="text-center text-muted mb-4">
                    { this.state.error ? 
                      <Alert color="danger">{this.state.error}</Alert> : 
                      <small>Are you sure you want to delete this cluster?</small> }
                  </div>
                  <Form role="form" onSubmit={(e)=>e.preventDefault()}>
                    <FormGroup>
                      <label className="form-control-label px-2">Delete cluster:</label>
                      {this.props.match.params.name}
                      <Input
                        className="input-group-alternative mb-3"
                        name="name"
                        type="text"
                        placeholder={"Type \""+this.props.match.params.name+"\" to delete"}
                        onChange={this.handleInputChange} 
                      />
                    </FormGroup>
                  </Form>
                </CardBody>
                <CardFooter className="py-3">
                  <div className="float-right">
                    <Link to={"/admin/cluster/details/"+this.props.match.params.name}>
                      <Button className="mx-1 py-2 px-3" color="secondary" size="lg">
                        Back
                      </Button>
                    </Link>
                    <Button
                      className="mx-1 py-2 px-3" 
                      color= { this.state.isMatched ? "danger" : "default" }
                      size="lg"
                      onClick={ this.state.buttonDisabled ? null : this.handleSubmit }
                    >
                      { this.state.isLoading ? "Deleting..." : "Delete" }
                    </Button>
                  </div>
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default Delete;
