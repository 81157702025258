import { getCookie } from '../helpers';

export const registerService = {
    signup,
    update,
    _delete,
    forget,
    reset
};

const headers = {
    'Content-Type': 'application/json',
    'X-CSRF-TOKEN': getCookie('csrf_access_token'),
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.error) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

function signup(email, password) {
    const requestOptions = {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify({ email, password })
    };
    return fetch(process.env.REACT_APP_API_URL+'/user', requestOptions)
        .then(handleResponse)
}

function update(user) {
    const requestOptions = {
        method: 'POST',
        headers: headers,
        body: user
    };
    return fetch(process.env.REACT_APP_API_URL+'/user', requestOptions)
        .then(handleResponse)
}

function _delete(email, password) {
    const requestOptions = {
        method: 'DELETE',
        headers: headers,
        body: JSON.stringify({ email, password })
    };
    return fetch(process.env.REACT_APP_API_URL+'/user', requestOptions)
        .then(handleResponse)
}

function forget(email) {
    const requestOptions = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({ email })
    };
    return fetch(process.env.REACT_APP_API_URL+'/user/forget', requestOptions)
        .then(handleResponse)
}

function reset(email, email_token, new_password) {
    const requestOptions = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({ email, email_token, new_password })
    };
    return fetch(process.env.REACT_APP_API_URL+'/user/reset', requestOptions)
        .then(handleResponse)
}
