/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from 'react-router-dom'
import PasswordStrengthBar from 'react-password-strength-bar';

// reactstrap components
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col
} from "reactstrap";
import { authenticationService, registerService } from '../../services/';

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      password2: '',
      passwordMatched: false,
      acceptsTOS: false,
      isLoading: false,
      buttonDisabled: false,
      error: null
    };

    // redirect to home if already logged in
    if (authenticationService.currentUserValue) { 
        this.props.history.push('/');
    }

    this.handleInputChange = this.handleInputChange.bind(this);
    this.matchPassword = this.matchPassword.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange(event) {
    const {value, name} = event.target
    this.setState({[name]: value}, this.matchPassword);
  }

  matchPassword() {
    if (this.state.password2 !== '' && this.state.password !== this.state.password2) {
      this.setState({error: "passwords must match", buttonDisabled: true, passwordMatched: false});
    } else {
      this.setState({error: null, buttonDisabled: false, passwordMatched: true});
    }
  }

  handleSubmit(event) {
    event.preventDefault()
    if ( this.state.email === '' || this.state.password === '' || !this.state.passwordMatched ){
      this.setState({error: "All feilds are required"});
      return null
    }
    if ( !this.state.acceptsTOS ) {
      this.setState({error: "You must accept the Terms of Service"});
      return null
    }
    this.setState({ isLoading: true, buttonDisabled: true, error: null });
    registerService.signup(this.state.email, this.state.password)
    .then(
        user => {
          authenticationService.login( this.state.email, this.state.password)
          .then(
            user => this.props.history.push({ pathname: "/" }),
            error => this.setState({isLoading: false, buttonDisabled: false, error: error})
            )},
        error => this.setState({ isLoading: false, buttonDisabled: false, error: error})
    )
  }

  render() {
    return (
      <>
        <Col lg="6" md="8">
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-white pb-5">
              <div className="text-muted text-center mb-3">
                <small>Sign up with</small>
              </div>
              <div className="btn-wrapper text-center">
                <Button
                  className="btn-neutral btn-icon"
                  color="default"
                  href={process.env.REACT_APP_API_URL+'/auth/github'}
                >
                  <span className="btn-inner--icon mr-1">
                    <img
                      alt="..."
                      src={
                        require("assets/img/icons/common/github.svg")
                          .default
                      }
                    />
                  </span>
                  <span className="btn-inner--text">Github</span>
                </Button>
                <Button
                  className="btn-neutral btn-icon ml-1"
                  color="default"
                  href={process.env.REACT_APP_API_URL+'/auth/google'}
                >
                  <span className="btn-inner--icon mr-1">
                    <img
                      alt="..."
                      src={
                        require("assets/img/icons/common/google.svg")
                          .default
                      }
                    />
                  </span>
                  <span className="btn-inner--text">Google</span>
                </Button>
              </div>
            </CardHeader>

            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                { this.state.error ? 
                  <Alert color="danger">{this.state.error}</Alert> : 
                  <small>Sign up with email</small> }
              </div>
              <Form role="form">
                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input name="email" placeholder="Email" type="email" onChange={this.handleInputChange}/>
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input name="password" placeholder="Password" type="password" onChange={this.handleInputChange}/>
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input name="password2" placeholder="Repeat password" type="password" onChange={this.handleInputChange}/>
                  </InputGroup>
                </FormGroup>
                <div className="text-muted font-italic">
                  <PasswordStrengthBar className="px-1 font-weight-700" password={this.state.password} />
                </div>
                <Row className="my-4">
                  <Col xs="12">
                    <div className="custom-control custom-control-alternative custom-checkbox">
                      <input
                        name="acceptsTOS"
                        className="custom-control-input"
                        id="customCheckRegister"
                        type="checkbox"
                        checked={this.state.acceptsTOS}
                        onChange={e => this.setState({ acceptsTOS: e.target.checked })}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="customCheckRegister"
                      >
                        <span className="text-muted">
                          I agree with the{" "}
                          <a href={process.env.REACT_APP_DOCS_LINK+'/terms'}>
                            Terms of Service
                          </a> &{" "}
                          <a href={process.env.REACT_APP_DOCS_LINK+'/privacy'}>
                            Privacy Policy
                          </a>
                        </span>
                      </label>
                    </div>
                  </Col>
                </Row>
                <div className="text-center">
                  <Button className="mt-4" color="primary" type="submit"
                  onClick={ this.state.buttonDisabled ? (e)=>e.preventDefault() : this.handleSubmit }
                  >
                    { this.state.isLoading ? "Creating..." : "Create account" }
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
          <Row className="mt-3">
            <Col className="text-right" xs="12">
              <Link className="text-light" to="/auth/login">
                <small>Login with existing account</small>
              </Link>
            </Col>
          </Row>
        </Col>
      </>
    );
  }
}

export default Register;
