import React, { useState, useEffect } from "react";

// reactstrap components
import {
  Alert,
  Button,
  Card,
  CardBody,
  Container,
  Col,
  Row
} from "reactstrap";
import { grafanaService } from 'services/';

const Monitoring = (props) => {
  const [grafanaToken, setGrafanaToken] = useState(null)
  useEffect(() => {
    grafanaService.token().then(
      data => setGrafanaToken(data.grafana_token),
      error => null
      )
  },[]);

  const getParams = ()=>{
    return new URLSearchParams(props.location.search);
  }

  const redirect_url = getParams().get('rd')

  return(
    <Container className="mt--7" fluid>
      <Row>
        <Col>
          <Card className="shadow">
            <CardBody className="px-lg-5 py-lg-3 bg-secondary">
            { grafanaToken === null ?
              <Alert className="text-center my-3" color="secondary">
                Generating link to the Monitoring Console...
              </Alert> :
              <Row>
                <Col xs="12" className="mx-auto text-center py-3">
                  <a href={`${process.env.REACT_APP_GRAFANA_URL}/grafana/login?rd=${redirect_url || process.env.REACT_APP_GRAFANA_URL+"%2Fdashboards"}&token=${grafanaToken}`} target="_blank" rel="noopener noreferrer">
                    <Button className="text-center" color="default">
                      <i className="fas fa-external-link-alt pr-2" />
                      Open Monitoring Console
                    </Button> 
                  </a>
                </Col>
              </Row>
            }
            {redirect_url !== null && grafanaToken !== null ?
             window.location.assign(`${process.env.REACT_APP_GRAFANA_URL}/grafana/login?rd=${redirect_url}&token=${grafanaToken}`):null}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
      )
}

export default Monitoring;
