/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  FormGroup,
  Form,
  Input
} from "reactstrap";
// core components


const CuratorForm = (props)=>{

  return (
    <Form role="form">
      <FormGroup>
        <label className="form-control-label px-2" >Curator Actions.yml</label>
        <Input
          className="input-group-alternative mb-3"
          name="curator_actions"
          type="textarea"
          value={props.cluster.curator_actions}
          onChange={props.handleInputChange}
          style={{'height': '500px', 'white-space': 'pre'}}
          defaultValue={`---
# Enter a valid actions.yml file of the following format for curator.
# These actions will be executed daily.
actions:
  1:
    action: delete_indices
    description: "Clean up ES by deleting old indices"
    options:
      timeout_override:
      continue_if_exception: False
      disable_action: True
      ignore_empty_list: True
    filters:
    - filtertype: age
      source: name
      direction: older
      timestring: '%Y.%m.%d'
      unit: days
      unit_count: 30
      exclude: False`}
        />
      </FormGroup>
    </Form>
  );
}


export default CuratorForm;
