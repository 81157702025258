/*!

=========================================================
* Argon Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";

// reactstrap components
import { Alert, Container } from "reactstrap";
import { userService, openFastspringPopup } from 'services/';

import background from "assets/img/theme/background.jpg";

const Header = (props) => {
  const [isVerified, setIsVerified] = useState(true);
  const [isActivated, setIsActivated] = useState(true);
  const [isResending, setIsResending] = useState(false);
  const [isActivating, setIsActivating] = useState(false);

  useEffect(() => {
    if (!isResending && !isActivating){
      setIsVerified(props.user.verified);
      setIsActivated(props.user.activated);
    }
  })

  const handlePopup = ()=>{
    openFastspringPopup(props.user.email, ()=>{setIsActivating(true); setIsActivated(true)})
  }
  const handleResend = ()=>{
    if (!isResending){
      setIsResending(true)
      userService.resend().then(
        data => {setIsVerified(true); setIsActivated(true)},
        error => null
        )
    }
  }

  return (
    <>
      <div
        className="header pb-8 pt-4 pt-md-8 d-flex align-items-center"
        style={{
          minHeight: "200px",
          backgroundImage: `url(${background})`,
          backgroundSize: "cover",
          backgroundPosition: "center top"
        }}>
        {/* Mask */}
        <span className="mask bg-gradient-default opacity-2" />
        <Container fluid>
        { !isVerified ?
          <Alert color="default" className="mb-0" onClick={handleResend}>
            Email not verified. Please check your Inbox. Or click <u style={{cursor:'pointer'}}>here</u> to resend link.
          </Alert> : null}
        { !isActivated && isVerified ?
          <Alert color="secondary" className="mb-0" onClick={handlePopup}>
            Contact support to activate your account or click <u style={{cursor:'pointer'}}>here</u> to activate by adding a payment method
          </Alert> : null}
        </Container>
      </div>
    </>
  );
};

export default Header;
