/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import {Link} from 'react-router-dom'

// reactstrap components
import {
  Alert,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Container,
  Row
} from "reactstrap";
// core components
import { clusterService } from 'services/';
import ClusterForm from './ClusterForm.js';
import S3Form from './S3Form.js';
import CuratorForm from './CuratorForm.js';

class Update extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cluster: {},
      isInitialized: false,
      isLoading: false,
      buttonDisabled: true,
      error: null
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount(){
    if (!this.state.isInitialized && this.props.cluster !== undefined ){
      this.props.cluster.cluster_type = this.props.cluster.type
      this.setState({cluster:this.props.cluster, isInitialized: true, buttonDisabled: false});
    }
  }

  componentDidUpdate(){
    if (!this.state.isInitialized && this.props.cluster !== undefined ){
      this.props.cluster.cluster_type = this.props.cluster.type
      this.setState({cluster:this.props.cluster, isInitialized: true, buttonDisabled: false});
    }
  }

  handleInputChange(event) {
    const {value, name} = event.target
    let cluster = this.state.cluster
    cluster[name] = value
    this.setState({cluster:cluster});
  }

  handleSubmit(event) {
    if ( this.state.cluster.name === '' ){
      this.setState({error: "All feilds are required"});
      return null
    }
    this.setState({ isLoading: true, buttonDisabled: true, error: null });
    clusterService.update(this.state.cluster)
    .then(
        cluster => this.updateSuccess(),
        error => this.setState({ isLoading: false, buttonDisabled: false, error: error})
    )
  }

  updateSuccess(cluster){
    this.props.history.push({ pathname: "/admin/cluster" })
    this.props.updateClusters()
  }

  getParams(){
    return new URLSearchParams(this.props.location.search);
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="align-middle mb-0">Update Cluster</h3>
                </CardHeader>
                <CardBody className="px-lg-5 py-lg-3 bg-secondary">
                  <div className="text-center text-muted mb-4">
                    { this.state.error ? 
                      <Alert color="danger">{this.state.error}</Alert> : 
                      <small>Modify an existing Cluster</small> }
                  </div>
                  { this.props.cluster ?
                  <ClusterForm
                    {...this.props}
                    handleInputChange={this.handleInputChange}
                    cluster={this.state.cluster}
                    isCreate={false}
                    hide={this.getParams().get('s3_keys') === 'true' || this.getParams().get('curator') === 'true'}
                    />
                  : null}
                  { this.getParams().get('s3_keys') === 'true' ?
                  <S3Form handleInputChange={this.handleInputChange} cluster={this.state.cluster}/>
                  : null }
                  { this.getParams().get('curator') === 'true' ?
                  <CuratorForm handleInputChange={this.handleInputChange} cluster={this.state.cluster}/>
                  : null }
                </CardBody>
                <CardFooter className="py-3">
                  <div className="float-right">
                    <Link to={"/admin/cluster/details/"+this.props.match.params.name}>
                      <Button className="mx-1 py-2 px-3" color="secondary" size="lg">
                        Back
                      </Button>
                    </Link>
                    <Button
                      className="mx-1 py-2 px-3" 
                      color="primary"
                      size="lg"
                      onClick={ this.state.buttonDisabled ? null : this.handleSubmit }
                    >
                      { this.state.isLoading ? "Updating..." : "Update" }
                    </Button>
                  </div>
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default Update;
