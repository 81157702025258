import { handleResponse } from '../helpers';

export const userService = {
    getAll,
    resend
};

function getAll() {
    const requestOptions = { method: 'GET', credentials: 'include' };
    return fetch(process.env.REACT_APP_API_URL+'/user', requestOptions).then(handleResponse);
}

function resend() {
    const requestOptions = { method: 'POST', credentials: 'include' };
    return fetch(process.env.REACT_APP_API_URL+'/user/resend', requestOptions).then(handleResponse);
}
