/*!

=========================================================
* Argon Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Profile from "views/admin/Profile.js";
import Register from "views/auth/Register.js";
import Login from "views/auth/Login.js";
import Reset from "views/auth/Reset.js";
import Forget from "views/auth/Forget.js";
import Billing from "views/admin/Billing.js";
import Clusters from "views/admin/Clusters.js";
import Monitoring from "views/admin/Monitoring.js";

var routes = [
  {
    path: "/cluster",
    name: "Clusters",
    icon: "ni ni-app text-primary",
    component: Clusters,
    layout: "/admin"
  },
  {
    path: "/user-account",
    name: "User",
    icon: "ni ni-single-02 text-yellow",
    component: Profile,
    layout: "/admin",
  },
  {
    path: "/billing",
    name: "Billing",
    icon: "ni ni-bullet-list-67 text-green",
    component: Billing,
    layout: "/admin"
  },
  {
    path: "/monitoring",
    name: "Monitoring",
    icon: "ni ni-chart-bar-32 text-red",
    component: Monitoring,
    layout: "/admin"
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
  },
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: Register,
    layout: "/auth"
  },
  {
    path: "/reset",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: Reset,
    layout: "/auth"
  },
  {
    path: "/forget",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: Forget,
    layout: "/auth"
  }
];
export default routes;
