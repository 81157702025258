/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import {Link} from 'react-router-dom'

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  Col,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Table,
  Container,
  Row
} from "reactstrap";
// core components

class List extends React.Component {

  colorClassFromState(cluster_state){
    const colorClass = {
      available: "bg-success",
      updating: "bg-primary",
      creating: "bg-primary",
      unavailable: "bg-danger",
      deleting: "bg-danger",
      unknown: "bg-danger"
    }
    return colorClass[cluster_state]
  }

  generateTable(cluster, index){

    return(
      <tr key={index}>
        <td className="text-right px-0">
          <UncontrolledDropdown>
            <DropdownToggle
              className="btn-icon-only text-light"
              href="#dropdown"
              role="button"
              size="lg"
              color=""
              onClick={e => e.preventDefault()}
            >
              <i className="fas fa-ellipsis-v" />
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow" right>
              <Link to={'/admin/cluster/details/'+cluster.name}>
                <DropdownItem>Details</DropdownItem>
              </Link>
              <Link to={'/admin/cluster/update/'+cluster.name}>
                <DropdownItem>Modify</DropdownItem>
              </Link>
              <Link to={'/admin/cluster/delete/'+cluster.name}>
                <DropdownItem className="text-danger">Delete</DropdownItem>
              </Link>
            </DropdownMenu>
          </UncontrolledDropdown>
        </td>
        <td>
          <Media className="align-items-center">
            <Media>
              <span className="mb-0 text-sm">
                <Link style={{color: "#525f7f"}} to={'/admin/cluster/details/'+cluster.name}>{cluster.name}</Link>
              </span>
            </Media>
          </Media>
        </td>
        <td>
          <Badge color="" className="badge-dot mr-4">
            <i className={this.colorClassFromState(cluster.state)}/>
            {cluster.state}
          </Badge>
        </td>
        <td>{cluster.version}</td>
        <td>{cluster.type}</td>
        <td>{cluster.data_node_count}</td>
        <td>{new Date(cluster.created_at.$date).toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric'})}</td>
      </tr>      
    )

  }
  render() {
    return (
      <>
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row>
                    <Col>
                      <h3 className="align-middle mb-2">ElasticSearch</h3>
                    </Col>
                    <Col>
                      <Link to="/admin/cluster/create">
                        <Button className="float-right text-nowrap p-2" color="primary" size="m">
                          <i className="fas fa-plus-circle pr-2" />
                          Launch Cluster
                        </Button>
                      </Link>
                    </Col>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col" />
                      <th scope="col">Name</th>
                      <th scope="col">Status</th>
                      <th scope="col">Version</th>
                      <th scope="col">Type</th>
                      <th scope="col">Data Nodes</th>
                      <th scope="col">Launch Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    { this.props.clusters.map((cluster,index) => this.generateTable(cluster,index)) }
                    {/* Ugly fix for low height */}
                    { this.props.clusters.length === 1 ? <tr><td colSpan="6"></td></tr> : null }
                    { this.props.isEmpty ? <tr>
                      <td colSpan="6">No active clusters.<br></br>Get started by launching a new cluster.</td>
                    </tr>: null }
                  </tbody>
                </Table>
                <CardFooter className="py-2">
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default List;
