export function openFastspringPopup(email, onClose){
  const script = document.createElement("script");
  script.id = 'fsc-api'
  script.src = 'https://d1f8f9xcsvx3ha.cloudfront.net/sbl/0.8.3/fastspring-builder.min.js';
  script.dataset.storefront = process.env.REACT_APP_FS_STOREFRONT
  // script.dataset.popupClosed = onClose
  document.body.appendChild(script);

  script.onload = () => {
    window.fastspring.builder.push({
    reset: true,
    products : [{ path: process.env.REACT_APP_FS_PRODUCT, quantity: 1}],
    paymentContact: { email: email },
    checkout: true
  }, onClose)}
}

export function openFastspringManage(){
  window.open(`https://${process.env.REACT_APP_FS_MANAGE}`, "_blank").focus()
}
