/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from 'react-router-dom'

// reactstrap components
import {
  Alert,
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col
} from "reactstrap";
import { authenticationService, registerService } from '../../services/';

class Forget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      isLoading: false,
      buttonDisabled: false,
      linkSent: false,
      error: null
    };

    // redirect to home if already logged in
    if (authenticationService.currentUserValue) { 
        this.props.history.push('/');
    }

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange(event) {
    const {value, name} = event.target
    this.setState({[name]: value});
  }

  handleSubmit(event) {
    event.preventDefault()
    if ( this.state.email === ''){
      this.setState({error: "A username or email is required"});
      return null
    }
    this.setState({ isLoading: true, buttonDisabled: true, error: null });
    registerService.forget(this.state.email).then(
      user => this.setState({linkSent: true}),
      error => this.setState({ isLoading: false, buttonDisabled: false, error: error})
    )
  }

  render() {
    return (
      <>
        <Col lg="6" md="8">
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
            {! this.state.linkSent ? <div>
              <div className="text-center text-muted mb-4">
                { this.state.error ? 
                  <Alert color="danger">{this.state.error}</Alert> : 
                  <small>Enter the username or email associated with your account</small> }
              </div>
              <Form role="form">
                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input name="email" placeholder="Email/Username" type="text" onChange={this.handleInputChange} />
                  </InputGroup>
                </FormGroup>

                <div className="text-center">
                  <Button className="mt-4" color="primary" type="submit"
                  onClick={ this.state.buttonDisabled ? (e)=>e.preventDefault() : this.handleSubmit }
                  >
                    { this.state.isLoading ? "Requesting..." : "Request Password Reset" }
                  </Button>
                </div>
              </Form>
              </div> : <Alert className="text-center" color="primary">Password reset link sent to your registered email.</Alert> }
            </CardBody>
          </Card>
          <Row className="mt-3">
            <Col xs="6">
              <Link className="text-light" to="/auth/register">
                <small>Create a new account</small>
              </Link>
            </Col>
            <Col className="text-right" xs="6">
              <Link className="text-light" to="/auth/login">
                <small>Back to Login</small>
              </Link>
            </Col>
          </Row>
        </Col>
      </>
    );
  }
}

export default Forget;
