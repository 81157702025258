/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import {Link} from 'react-router-dom'

// reactstrap components
import {
  Button,
  Badge,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Container,
  Col,
  Row
} from "reactstrap";
// core components

  const Feild = (props)=>{
    return <>
      <label className="form-control-label">{props.label}</label>
      <div className="mx-0 p-2 rounded">{props.content}</div>
    </>
  }

class Details extends React.Component {

  colorClassFromState(cluster_state){
    const colorClass = {
      available: "bg-success",
      updating: "bg-primary",
      creating: "bg-primary",
      unavailable: "bg-danger",
      deleting: "bg-danger",
      unknown: "bg-danger"
    }
    return colorClass[cluster_state]
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col mx-auto">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="align-middle mb-0">Cluster Details</h3>
                </CardHeader>
                <CardBody className="px-lg-5 py-lg-3 bg-secondary">
                  { this.props.cluster !== undefined ? <Row>
                    <Col md="8" className="pb-4">
                      <Feild label="Name" content={this.props.cluster.name}/>
                    </Col>
                    <Col md="4" className="pb-4">
                      <Feild label="State" content={
                        <Badge color="" className="badge-dot mr-4">
                          <i className={this.colorClassFromState(this.props.cluster.state)}/>
                          {this.props.cluster.state}
                        </Badge>}/>
                    </Col>
                    <Col md="12" className="pb-4">
                      <Feild label="ElasticSearch URL" content={this.props.cluster.state !== "creating"?
                        <a href={"https://"+this.props.cluster.es_uri} target="_blank" rel="noopener noreferrer">{this.props.cluster.es_uri}</a>
                      : "-"}/>
                    </Col>
                    <Col md="12" className="pb-0">
                      <Feild label="Kibana URL" content={this.props.cluster.state !== "creating"?
                        <a href={"https://"+this.props.cluster.kibana_uri} target="_blank" rel="noopener noreferrer">{this.props.cluster.kibana_uri}</a>
                      : "-"}/>
                    </Col>
                    <Col md="12"><hr/></Col>
                    <Col md="6" className="pb-4">
                      <Feild label="Default Username" content="admin"/>
                    </Col>
                    <Col md="6" className="pb-4">
                      <Feild label="Cluster Version" content={this.props.cluster.version}/>
                    </Col>
                    <Col md="6" className="pb-2">
                      <Feild label="Cluster Type" content={this.props.cluster.type}/>
                    </Col>
                    <Col md="6" className="pb-2">
                      <Feild label="Data Nodes" content={this.props.cluster.data_node_count}/>
                    </Col>
                    <Col md="12"><hr/></Col>
                    <Col md="6" className="pb-2">
                      <Feild label="ElasticSearch Whitelist" content={this.props.cluster.es_whitelist}/>
                    </Col>
                    <Col md="6" className="pb-2">
                      <Feild label="Kibana Whitelist" content={this.props.cluster.kibana_whitelist}/>
                    </Col>
                  </Row>: null}
                </CardBody>
                <CardFooter className="py-3">
                 <div className="float-right">
                   <Link to="/admin/cluster">
                     <Button className="mx-1 py-2 px-3" color="secondary" size="lg">
                       Back
                     </Button>
                   </Link>
                   <Link to={"/admin/cluster/update/"+this.props.match.params.name}>
                     <Button className="mx-1 py-2 px-3" color= "primary" size="lg">Modify</Button>
                   </Link>
                 </div>
               </CardFooter>
              </Card>
            </div>
          </Row>

          <Card className="bg-secondary shadow mt-5">
            <CardBody>
              <h6 className="heading-small text-muted mb-4">Monitoring</h6>
              <Row className="mb-4">
                <Col xs="12" lg="6" className="pl-lg-4">
                  <span className="text-sm">Monitor and analyse cluster utilization.<br/>
                   View detailed metrics about your cluster.</span>
                </Col>
                <Col xs="12" lg="6">
                  {console.log(this.props.cluster)}
                  {this.props.cluster ?
                  <a href={`${process.env.REACT_APP_GRAFANA_URL}/d/${this.props.cluster.namespace}/${this.props.cluster.name}`} target="_blank" rel="noopener noreferrer">
                    <Button className="float-right mt-3 mt-lg-0" color="default" type="button">
                      <i className="fas fa-external-link-alt pr-2" />
                      Monitoring Dashboard
                    </Button>
                  </a>
                  : null}
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Card className="bg-secondary shadow mt-5">
            <CardBody>
              <h6 className="heading-small text-muted mb-4">Update S3 Keys</h6>
              <Row className="mb-4">
                <Col xs="12" lg="8" className="pl-lg-4">
                  <span className="text-sm">Change the keys used to access S3 for backups.<br/>
                    You can setup an S3 bucket for ElasticSearch snapshots.</span>
                </Col>
                <Col xs="12" lg="4">
                  <Link to={"/admin/cluster/update/"+this.props.match.params.name+"?s3_keys=true"}>
                    <Button className="float-right mt-3 mt-lg-0" color="success" outline type="button">
                      Update S3 Keys
                    </Button>
                  </Link>
                </Col>
              </Row>
              <hr className="mt-5" />
              <h6 className="heading-small text-muted mb-4">Update Curator</h6>
              <Row className="mb-4">
                <Col xs="12" lg="7" className="pl-lg-4">
                  <span className="text-sm">Update Curator actions.yml to update daily actions.<br/>
                    You can backup and delete indices using Curator.</span>
                </Col>
                <Col xs="12" lg="5">
                  <Link to={"/admin/cluster/update/"+this.props.match.params.name+"?curator=true"}>
                    <Button className="float-right mt-3 mt-lg-0" color="primary" outline type="button">
                      Update Curator
                    </Button>
                  </Link>
                </Col>
              </Row>
              <hr className="mt-5" />
              <h6 className="heading-small text-muted mb-4">Delete Cluster</h6>
              <Row className="mb-4">
                <Col xs="12" lg="8" className="pl-lg-4">
                  <span className="text-sm">Make sure you have a backup before deleting your cluster.<br/>
                    Any data on the cluster will not be recoverable after it is deleted.</span>
                </Col>
                <Col xs="12" lg="4">
                  <Link to={"/admin/cluster/delete/"+this.props.match.params.name}>
                    <Button className="float-right mt-3 mt-lg-0" color="danger" outline type="button">
                      Delete Cluster
                    </Button>
                  </Link>
                </Col>
              </Row>
            </CardBody>
          </Card>

        </Container>
      </>
    );
  }
}

export default Details;
