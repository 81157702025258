/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row
} from "reactstrap";
// core components
import { billingService } from 'services/';

const ListItem = (props) => {
  return <tr>
      <th scope="row">
        <Media className="align-items-center">
          <span className="mb-0 text-sm">
            {new Date(props.from_time.$date).toLocaleDateString('en-US', { month: 'long', year: 'numeric'})}
          </span>
        </Media>
      </th>
      <td className="text-right">${props.cost} USD</td>
      <td>
        <Badge color="" className="badge-dot mr-4">
          {props.paid ? <><i className="bg-success" />Paid</> : <><i className="bg-warning" />Unpaid</> }
        </Badge>
      </td>
      <td>
        <a href={`${process.env.REACT_APP_API_URL}/billing/invoice/${props._id ? props._id.$oid : "current"}.html?token=${props.token}`} target="_blank" rel="noopener noreferrer">
        <span className="btn btn-outline-primary py-1 px-2 mx-1">
          <i className="fas fa-eye" /> View
        </span>
        </a>
        <a href={`${process.env.REACT_APP_API_URL}/billing/invoice/${props._id ? props._id.$oid : "current"}.pdf?token=${props.token}`} target="_blank" rel="noopener noreferrer">
        <span className="btn btn-outline-primary py-1 px-2 mx-1">
          <i className="fas fa-file-download" /> Download
        </span>
        </a>
      </td>
    </tr>
}

const PageNumbers = (props) => {
  const prev_page = props.page > 1 ? props.page-1 : props.page
  const next_page = props.page < props.totalPages ? props.page+1 : props.page
  return <nav aria-label="...">
      <Pagination className="pagination justify-content-end mb-0" listClassName="justify-content-end mb-0">
        <PaginationItem>
          <PaginationLink href="" onClick={e => {e.preventDefault(); props.handleChange(prev_page)}}>
            <i className="fas fa-angle-left" />
            <span className="sr-only">Previous</span>
          </PaginationLink>
        </PaginationItem>
      {[...Array(props.totalPages)].map((e, i) => {
        return <PaginationItem className={props.page === i+1 ? "active" : null}>
          <PaginationLink href="" onClick={e => {e.preventDefault(); props.handleChange(i+1)}}>
            {i+1}
          </PaginationLink>
        </PaginationItem>
      })}
        <PaginationItem>
          <PaginationLink href="" onClick={e => {e.preventDefault(); props.handleChange(next_page)}}>
            <i className="fas fa-angle-right" />
            <span className="sr-only">Next</span>
          </PaginationLink>
        </PaginationItem>
      </Pagination>
    </nav>
}

class Billing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentBill: null,
      bills: [],
      page: 1,
      totalPages: 1,
      token: null
    };

    this.handlePageChange = this.handlePageChange.bind(this)
  }

  componentDidMount(){
    billingService.list(1).then(
      bill => this.setState({bills: bill.bills, page: bill.page, totalPages: bill.total_pages, token: bill.token}),
      error => null
    )
    billingService.current().then(
      bill => this.setState({currentBill: bill}),
      error => null
    )
  }

  handlePageChange(page){
    this.setState({page: page, currentBill: null, bills: []})
    if (page === 1){
      billingService.current().then(
        bill => this.setState({currentBill: bill}),
        error => null
      )
    }
    billingService.list(page).then(
      bill => this.setState({bills: bill.bills, page: bill.page, totalPages: bill.total_pages}),
      error => null
    )
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">Invoices</h3>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Month</th>
                      <th scope="col">Cost</th>
                      <th scope="col">Status</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    { this.state.currentBill && this.state.page === 1 ?
                      ( this.state.bills.length === 0 && this.state.currentBill.cost === 0 ?
                       <tr><td colSpan="4">No invoice yet.<br></br>Invoices will be automatically generated after a cluster is launched.</td></tr>
                       : <ListItem {...this.state.currentBill} token={this.state.token}/> )
                      : null}
                    { this.state.bills.map((bill,index) => <ListItem key={bill._id.$oid} {...bill} token={this.state.token}/> )}
                  </tbody>
                </Table>
                <CardFooter className="py-4">
                  {this.state.totalPages < 2 ? null:
                  <PageNumbers page={this.state.page} totalPages={this.state.totalPages} handleChange={this.handlePageChange}/>}
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default Billing;
