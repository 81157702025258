import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { authenticationService } from '../services';

export const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => {
        const currentUser = authenticationService.currentUserValue;
        if (!currentUser) {
            // not logged in so redirect to login page and save the return url
            localStorage.setItem('redirectUri', JSON.stringify(props.location));
            return <Redirect to={{ pathname: '/auth/login' }} />
        }

        // authorised so return component
        return <Component {...props} />
    }} />
)

export default PrivateRoute;
