/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import {Link} from 'react-router-dom'
import { CopyToClipboard } from "react-copy-to-clipboard";

// reactstrap components
import {
  Alert,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Container,
  Modal,
  Row,
  UncontrolledTooltip
} from "reactstrap";
// core components
import { clusterService } from 'services/';
import ClusterForm from './ClusterForm.js';

class Create extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cluster: {},
      isLoading: false,
      buttonDisabled: false,
      successModal: false,
      creationResponse: {},
      error: null
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.creationSuccess = this.creationSuccess.bind(this);
  }

  handleInputChange(event) {
    const {value, name} = event.target
    let cluster = this.state.cluster
    cluster[name] = value
    this.setState({cluster:cluster});
  }


  handleSubmit(event) {
    if ( this.state.cluster.name === '' ){
      this.setState({error: "All feilds are required"});
      return null
    }
    this.setState({ isLoading: true, buttonDisabled: true, error: null });
    clusterService.create(this.state.cluster)
    .then(
        cluster => this.creationSuccess(cluster),
        error => this.setState({ isLoading: false, buttonDisabled: false, error: error})
    )
  }

  creationSuccess(cluster){
    this.setState({successModal: true, creationResponse: cluster})
    this.props.updateClusters()
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="align-middle mb-0">Launch Cluster</h3>
                </CardHeader>
                <CardBody className="px-lg-5 py-lg-3 bg-secondary">
                  <div className="text-center text-muted mb-4">
                    { this.state.error ? 
                      <Alert color="danger">{this.state.error}</Alert> : 
                      <small>Launch an ElasticSearch Cluster</small> }
                  </div>
                <ClusterForm {...this.props} handleInputChange={this.handleInputChange} cluster={this.state.cluster} isCreate={true}/>
                </CardBody>
                <CardFooter className="py-3">
                  <div className="float-right">
                    <Link to="/admin/cluster">
                      <Button className="mx-1 py-2 px-3" color="secondary" size="lg">
                        Cancel
                      </Button>
                    </Link>
                    <Button
                      className="mx-1 py-2 px-3" 
                      color="primary"
                      size="lg"
                      onClick={ this.state.buttonDisabled ? null : this.handleSubmit }
                    >
                      { this.state.isLoading ? "Launching..." : "Launch" }
                    </Button>
                  </div>
                </CardFooter>
              </Card>
            </div>
          </Row>
            <Modal className="modal-dialog-centered" isOpen={this.state.successModal} >
              <div className="modal-header">
                <h3 className="modal-title" id="modal-title-default">
                  Cluster Created
                </h3>
              </div>
              <div className="modal-body">
                <Alert color="default" className="text-center mx-auto">
                  Copy these credentials to access the cluster<br/>
                  For security these will not be displayed again
                </Alert>
                <label className="form-control-label pt-0 px-2">Username</label>
                <Button className="btn-icon-clipboard text-center" id="username" color="link">{this.state.creationResponse.username}</Button>
                <label className="form-control-label px-2">Password</label>
                <CopyToClipboard
                  text={this.state.creationResponse.password} 
                  onCopy={() => this.setState({ copiedText: this.state.creationResponse.password })}
                >
                <Button className="btn-icon-clipboard text-center" id="password" color="link">{this.state.creationResponse.password}</Button>
                </CopyToClipboard>
                <UncontrolledTooltip delay={0} trigger="hover focus" placement="top" target="password">
                  {this.state.copiedText === this.state.creationResponse.password
                    ? <span style={{"padding-left": "1.2rem", "padding-right":"1.2rem"}}>Copied</span>
                    : <span>Click to Copy</span>}
                </UncontrolledTooltip>
              </div>
              <div className="modal-footer">
                <Link to="/admin/cluster">
                  <Button color="primary" type="button">Got it!</Button>
                </Link>
              </div>
            </Modal>
        </Container>
      </>
    );
  }
}

export default Create;
