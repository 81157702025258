import { getCookie, handleResponse } from '../helpers';

export const clusterService = {
    create,
    read,
    update,
    _delete
};

const headers = {
    'Content-Type': 'application/json',
    'X-CSRF-TOKEN': getCookie('csrf_access_token'),
}

function create(cluster) {
    const requestOptions = {
        method: 'PUT',
        headers: headers,
        credentials: 'include',
        body: JSON.stringify(cluster)
    };
    return fetch(process.env.REACT_APP_API_URL+'/cluster', requestOptions).then(handleResponse);
}

function read() {
    const requestOptions = { method: 'GET', credentials: 'include' };
    return fetch(process.env.REACT_APP_API_URL+'/cluster', requestOptions).then(handleResponse);
}

function update(cluster) {
    const requestOptions = {
        method: 'POST',
        headers: headers,
        credentials: 'include',
        body: JSON.stringify(cluster)
    };
    return fetch(process.env.REACT_APP_API_URL+'/cluster', requestOptions).then(handleResponse);
}

function _delete(cluster) {
    const requestOptions = {
        method: 'Delete',
        headers: headers,
        credentials: 'include',
        body: JSON.stringify(cluster)
    };
    return fetch(process.env.REACT_APP_API_URL+'/cluster', requestOptions).then(handleResponse);
}
