import { handleResponse } from '../helpers';

export const billingService = {
    current,
    list
};

function current() {
    const requestOptions = { method: 'GET', credentials: 'include' };
    return fetch(process.env.REACT_APP_API_URL+'/billing/current', requestOptions).then(handleResponse);
}

function list(page) {
    const requestOptions = { method: 'GET', credentials: 'include' };
    return fetch(process.env.REACT_APP_API_URL+'/billing/list/'+page, requestOptions).then(handleResponse);
}
