import { handleResponse } from '../helpers';

export const grafanaService = {
    token
};

function token() {
    const requestOptions = { method: 'GET', credentials: 'include' };
    return fetch(process.env.REACT_APP_API_URL+'/grafana/token', requestOptions).then(handleResponse);
}
