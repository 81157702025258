/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from 'react-router-dom'
import PasswordStrengthBar from 'react-password-strength-bar';

// reactstrap components
import {
  Alert,
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col
} from "reactstrap";
import { authenticationService, registerService } from '../../services/';

class Reset extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      passwordMatched: false,
      isLoading: false,
      buttonDisabled: false,
      error: null
    };

    // redirect to home if already logged in
    if (authenticationService.currentUserValue) { 
        this.props.history.push('/');
    }

    this.handleInputChange = this.handleInputChange.bind(this);
    this.matchPassword = this.matchPassword.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange(event) {
    const {value, name} = event.target
    this.setState({[name]: value});
  }

  matchPassword(event) {
    if (event.target.value !== this.state.password) {
      this.setState({error: "passwords must match", buttonDisabled: true, passwordMatched: false});
    } else {
      this.setState({error: null, buttonDisabled: false, passwordMatched: true});
    }

  }

  getParams(){
    return new URLSearchParams(this.props.location.search);
  }

  handleSubmit(event) {
    event.preventDefault()
    if ( this.state.password === '' || !this.state.passwordMatched ){
      this.setState({error: "A password is required"});
      return null
    }
    this.setState({ isLoading: true, buttonDisabled: true, error: null });
    registerService.reset(this.getParams().get('email'), this.getParams().get('email_token'), this.state.password)
    .then(
        user => {
          authenticationService.login( this.getParams().get('email'), this.state.password)
          .then(
            user => this.props.history.push({ pathname: "/" }),
            error => this.setState({isLoading: false, buttonDisabled: false, error: error})
            )},
        error => this.setState({ isLoading: false, buttonDisabled: false, error: error})
    )
  }

  render() {
    return (
      <>
        <Col lg="6" md="8">
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                { this.state.error ? 
                  <Alert color="danger">{this.state.error}</Alert> : 
                  <small>Reset your password</small> }
              </div>
              <Form role="form">
                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-hat-3" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input name="email" placeholder="Email" value={this.getParams().get('email')} type="text"/>
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input name="password" placeholder="Password" type="password" onChange={this.handleInputChange}/>
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input name="password2" placeholder="Repeat password" type="password" onChange={this.matchPassword}/>
                  </InputGroup>
                </FormGroup>
                <div className="text-muted font-italic">
                  <PasswordStrengthBar className="px-1 font-weight-700" password={this.state.password} />
                </div>
                <div className="text-center">
                  <Button className="mt-4" color="primary" type="submit"
                  onClick={ this.state.buttonDisabled ? (e)=>e.preventDefault() : this.handleSubmit }
                  >
                    { this.state.isLoading ? "Reseting..." : "Reset Password" }
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
          <Row className="mt-3">
            <Col className="text-right" xs="12">
              <Link className="text-light" to="/auth/login">
                <small>Back to Login</small>
              </Link>
            </Col>
          </Row>
        </Col>
      </>
    );
  }
}

export default Reset;
