/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect }  from "react";

// reactstrap components
import {
  Col,
  FormGroup,
  Form,
  Input,
  Row
} from "reactstrap";
// core components

const ClusterForm = (props) => {

  const getParams = ()=>{
    return new URLSearchParams(props.location.search);
  }
  const version = getParams().get('version') || "7.10.2"
  const cluster_type = getParams().get('cluster_type') || "Standard"
  const data_node_count = getParams().get('data_node_count') || "2"
  const es_whitelist = getParams().get('es_whitelist') || "0.0.0.0/0"
  const kibana_whitelist = getParams().get('kibana_whitelist') || "0.0.0.0/0"

  useEffect(() => {
    if (props.isCreate){
      props.handleInputChange({target:{name: "name", value: ""}})
      props.handleInputChange({target:{name: "version", value: version}})
      props.handleInputChange({target:{name: "cluster_type", value: cluster_type}})
      props.handleInputChange({target:{name: "data_node_count", value: data_node_count}})
      props.handleInputChange({target:{name: "es_whitelist", value: es_whitelist}})
      props.handleInputChange({target:{name: "kibana_whitelist", value: kibana_whitelist}})
    }
  },[]);


  return (
    <Form role="form" style={props.hide ? { display: 'none' } : null } onSubmit={(e)=>e.preventDefault()}>
      <Row>
        <Col lg="12">
          <FormGroup>
            <label
              className="form-control-label px-2"
              htmlFor="input-username"
            >
              Cluster Name
            </label>
            <Input
              className={props.isCreate ? "input-group-alternative mb-3": "input-group-alternative mb-3 text-secondary"}
              name="name"
              type="text"
              value={props.isCreate ? null: props.cluster.name}
              disabled={!props.isCreate}
              placeholder="Enter a name for the cluster"
              onChange={props.handleInputChange}
            />
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup>
            <label className="form-control-label px-2">
              Cluster Version
            </label>
            <Input
              className="input-group-alternative mb-3"
              name="version"
              type="select"
              value={props.cluster.version}
              onChange={props.handleInputChange}
            >
              <option>7.10.2</option>
              <option>7.9.1</option>
              <option>7.8.0</option>
            </Input>
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup>
            <label className="form-control-label px-2" >
              Cluster Type
            </label>
            <Input
              className="input-group-alternative mb-3"
              name="cluster_type"
              type="select"
              value={props.cluster.cluster_type}
              disabled={!props.isCreate}
              onChange={props.handleInputChange}
            >
              <option>Basic</option>
              <option>Standard</option>
              <option>Pro</option>
              <option>Extreme</option>
            </Input>
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup>
            <label className="form-control-label px-2" >
              Data Nodes
            </label>
            <Input
              className="input-group-alternative mb-3"
              name="data_node_count"
              type="select"
              value={props.cluster.data_node_count}
              onChange={props.handleInputChange}
            >
              <option>2</option>
              <option>3</option>
              <option>4</option>
            </Input>
          </FormGroup>
        </Col>
        <Col sm="12"><hr/></Col>
        <Col lg="6">
          <FormGroup>
            <label className="form-control-label px-2" htmlFor="input-username">
              ElasticSearch Whitelist
            </label>
            <Input
              className="input-group-alternative mb-3"
              name="es_whitelist"
              type="text"
              value={props.cluster.es_whitelist}
              placeholder="Enter a comma separated list of IP blocks to allow"
              onChange={props.handleInputChange}
            />
          </FormGroup>
        </Col>
        <Col lg="6">
          <FormGroup>
            <label className="form-control-label px-2" htmlFor="input-username">
              Kibana Whitelist
            </label>
            <Input
              className="input-group-alternative mb-3"
              name="kibana_whitelist"
              type="text"
              value={props.cluster.kibana_whitelist}
              placeholder="Enter a comma separated list of IP blocks to allow"
              onChange={props.handleInputChange}
            />
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
}


export default ClusterForm;
